@import '_link-colors';

.mat-table {
  background: unset !important;
  color: inherit !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgba(var(--color-primary-rgb), 0.2);
}

.mat-cell,
.mat-footer-cell {
  color: inherit;
}

.mat-header-cell {
  color: inherit;
}

.mat-table-sticky {
  background: var(--color-bg);
}
.mat-mdc-table {
  background: unset !important;
}
