.mat-button-toggle-standalone.mat-button-toggle-appearance-standard,
.mat-button-toggle-group-appearance-standard {
  border-radius: 6px;
}

.mat-button-toggle-group-appearance-standard {
  border: solid 1px var(--color-bg-tertiary);
}

.mat-button-toggle-appearance-standard {
  background-color: var(--color-bg);
  color: var(--color-text-primary);
  font-size: 0.875rem;
  transition: all 0.2s;
}
.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked):hover {
  background-color: var(--color-bg-tertiary);
}

.mat-button-toggle-checked:hover {
  filter: brightness(1.05);
}
.mat-button-toggle-checked {
  background-color: var(--color-primary);
  color: var(--color-text-primary);
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: var(--color-text-primary);
}

.mat-button-toggle-appearance-standard .mat-button-toggle-focus-overlay {
  background-color: var(--color-bg-tertiary);
  border-bottom: none !important;
  opacity: 0 !important;
}

mat-button-toggle.cdk-focused {
  &:not(.mat-button-toggle-checked) {
  }
  &.mat-button-toggle-checked {
  }
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: 32px;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  letter-spacing: 0.2px;
}
