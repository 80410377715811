:root:not([env='native']) {
  body {
    overflow-y: auto !important;
    overflow-x: hidden;
  }
}

body {
  font-family: Inter, Roboto, sans-serif !important;
  color: var(--color-text-primary);
}
