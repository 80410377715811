@import '_link-colors';

.mat-dialog-container {
  background: var(--color-bg);
  color: var(--color-text-primary);
  box-shadow: none;
}

.dialog-transparent .mat-dialog-container {
  background: transparent;
}

.cdk-global-overlay-wrapper {
  align-items: center;
}

.cdk-overlay-pane {
  z-index: 1000000;
  -webkit-app-region: no-drag;
  margin-top: 0;
}
