@import '_breakpoints';

:root {
  --window-padding-top: 8px;
  --window-padding-right: 12px;
  --window-padding-left: 12px;
  --window-padding-bottom: 16px;
  --window-titlebar-height: 52px;
  --window-title-bar-controls: 144px;
  --launcher-item-margin: 2px 16px;
  --launcher-item-margin-side: 16px;
  --launcher-selected-header-margin-side: 20px;
  --footer-credit-height: 24px;
  --first-result-margin-top: 16px;

  --favorites-page-padding-top: 20px;
  --calendar-page-padding-top: 20px;

  --titlebar-nargin-top: 16px;

  --hub-side-padding: 16px;

  --app-search-view-input-height: 40px;
  --luncher-search-view-input-height: 32px;
  --search-view-padding-top: 48px;
  --search-view-height: calc(var(--app-search-view-input-height) + var(--search-view-padding-top));

  --search-view-clear-button-width: 60px;

  --bar-width: 1000px;
  --bar-height: 56px;
  --minimize-side-bar-width: 48px;
  --panel-height: 600px;
  --menu-width: 180px;
  --hub-content-width: calc(var(--bar-width) - var(--menu-width));
  --signin-width: 845px;
  --signin-height: 575px;
  --signin-main-width: 580px;
  --window-shadow: 0 4px 10px 1px rgba(28, 28, 32, 0.5);
  --scroller-track-width: 50px;

  --bar-breakpoint-s: 1366px;

  --developer-header-height: 40px;
  --developer-tabs-height: 39px;
  --developer-actions-height: 45px;

  --border-radius-popup: 6px;
  --padding-popup: 2px 0;

  --banner-height: 40px;

  // Background tertiary rgb

  @include respond-to-width('small') {
    --bar-width: 800px;
    --panel-height: 480px;
  }

  @media screen and (max-height: 600px) {
    --panel-height: 480px;
  }
}
