.windows-control {
  position: relative;
  display: inline-block;
  float: left;
  @include size(14px);
  width: 48px;
  padding: 0;
  height: 100%;
  overflow: hidden;
  &::after {
    font-family: 'chase-icons';
    position: absolute;
    transition: 0.1s;
    top: 55%;
    left: 50%;
    font-size: 13px;
    color: var(--color-text-tertiary);
    transform: translate(-50%, calc(-50% - 1px));
  }
  &:hover {
    @include add-overlay(8);
    &:after {
      color: var(--color-text-primary);
    }
  }
  &.window__close:hover {
    &:after {
      color: var(--white);
    }
  }
}
