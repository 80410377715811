a {
  &:hover {
    color: inherit;
  }
}

menu {
  padding: 0;
  margin: 0;
}

small {
  font-size: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  font-weight: unset;
}

p {
  padding: 0;
  margin: 0;
}

button {
  box-shadow: none;
  outline: none;
  background-color: unset;
  border: unset;
  color: inherit;
  font: inherit;
  -webkit-app-region: no-drag;
}

input {
  font-family: Inter;
  font: inherit;
  color: inherit;
}

*:focus {
  outline: none;
  outline-color: none;
  outline-width: 0;
}

textarea {
  color: inherit;
  font: inherit;
  border: none;
}
