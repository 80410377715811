@import '_mixin';

.mat-select-panel {
  background: var(--color-bg) !important;
  color: var(--color-text-secondary);
  margin-top: 2.5em;
  border: 1px solid var(--color-border-popup);
  border-radius: 6px;
  box-shadow: var(--elevation-1);

  &::-webkit-scrollbar-track {
    margin-top: 28px;
    [window-type='launcher'] & {
      background-color: var(--color-bg-launcher);
      &:before {
        content: '';
      }
    }
  }
}

.mat-select-panel:not([class*='mat-elevation-z']) {
  box-shadow: var(--elevation-1);
}

[aria-expanded='true'] .mat-select-arrow {
  transform: rotate(-270deg);
}

.mat-select-arrow-wrapper {
  display: block !important;
}

.mat-select-arrow {
  position: relative;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 1em !important;
  height: 1em !important;
  transform-origin: center center;
  transform: rotate(-90deg);
  transition: all 0.2s;
  margin: unset !important;
  margin-right: 4px !important;
  margin-left: 10px !important;
  &::after {
    top: -0.5em;
    right: 0;
    position: absolute;
    content: '\e93b';
    font-family: 'chase-icons';
  }
}

.mat-select-placeholder {
  @include inherit-font;
  transition: none !important;
}

.mat-select-trigger {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  transform: translateY(-2px);
}
