//This is util classes meant for reset. It's a bit safer to use then hard-reset all values. Just add 'reset' class :)

// Lists
ol.reset,
ol.reset li,
ol.reset ol li,
ul.reset,
ul.reset li,
ul.reset ul li,
li.reset {
  margin: 0;
  padding: 0;
  text-indent: 0;
  list-style-type: none;
}

button.reset {
  padding: 0 !important;
  min-width: unset;
  height: fit-content;
  min-height: unset;
}
