.cdk-virtual-scroll-orientation-vertical .cdk-virtual-scroll-content-wrapper {
  max-width: 100%;
}

.cdk-virtual-scroll-spacer {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px !important;
  width: 1px;
  transform-origin: 0 0;

  // Note: We can't put `will-change: transform;` here because it causes Safari to not update the
  // viewport's `scrollHeight` when the spacer's transform changes.

  [dir='rtl'] & {
    right: 0;
    left: auto;
    transform-origin: 100% 0;
  }
}

