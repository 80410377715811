@use 'sass:math';

$limitContent: 1440px;

@mixin vibrancyBackground {
  body[env='native'][sidebar-custom-color='true'] :host {
    background-color: rgba(var(--side-bar-color-bg-secondary), 0.8);
  }
}

@mixin size($size) {
  width: $size;
  height: $size;
}

@mixin maxSize($size) {
  max-width: $size;
  max-height: $size;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin flex-center-horizontal {
  display: flex;
  align-items: center;
}

@mixin flex-center-vertical {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@mixin center-full {
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

@mixin limit-content {
  width: 100%;
  max-width: $limitContent;
}

@mixin padding-content($paddingTop: 0, $paddingBottom: 0) {
  @include respond-to-width('large') {
    padding: $paddingTop 48px $paddingBottom;
  }

  @include respond-to-width('medium') {
    padding: $paddingTop 24px $paddingBottom;
  }
}

@mixin line-clamp($lines, $lineHeight) {
  display: -webkit-box;
  -webkit-line-clamp: $lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: normal;
  line-height: $lineHeight;
}

@mixin flex-gap($gap, $itemsInLine: 0) {
  & > * {
    margin-right: $gap;
    margin-bottom: $gap;
  }

  @if $itemsInLine >0 {
  }

  * > :nth-child(#{$itemsInLine}n + #{$itemsInLine}) {
    margin-right: 0;
  }

  @if $itemsInLine ==0 {
    & > * {
      margin-bottom: 0;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }
}

@mixin truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@mixin gradient-text {
  background: linear-gradient(225deg, #419ae3 0%, #005da8 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin fadein($position: sticky, $bottom: 0, $width: auto) {
  position: $position;
  bottom: $bottom;
  width: $width;
  right: 0;
  height: 56px;
  z-index: 2;
  background: linear-gradient(180deg, rgba(35, 35, 41, 0) 0%, #232329 71.09%, #232329 100%);
}

@mixin fadeout($top: unset, $right: unset, $bottom: unset, $left: unset, $height: 56px) {
  position: absolute;
  bottom: $bottom;
  right: $right;
  left: $left;
  top: $top;
  height: $height;
  z-index: 2;
  background: linear-gradient(180deg, rgba(35, 35, 41, 0) 0%, #232329 71.09%, #232329 100%);
  pointer-events: none;
}

@mixin inherit-font {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  letter-spacing: inherit;
}

/// Mixin to add a layer on top of the current item
/// @param {'false | true'} $important - if true adds !important to values, defaults to false
/// @require $level
@mixin add-overlay($level, $borderRadius: 0px, $color: var(--color-base-overlay), $important: 'false') {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;

    @if ($color ==var(--color-base-overlay)) {
      @if $level ==4 {
        $color: var(--color-overlay-low-transparency);
      } @else if $level ==8 {
        $color: var(--color-overlay-medium-transparency);
      } @else if $level ==12 {
        $color: var(--color-overlay-high-transparency);
      } @else {
        $color: rgba($color, math.div($level, 100));
      }

      background-color: $color !important;
    } @else {
      background-color: rgba($color, math.div($level, 100)) !important;
    }

    @if $important == 'true' {
      border-radius: $borderRadius !important;
    } @else {
      border-radius: $borderRadius;
    }
  }
}

@mixin add-text-overlay($level) {
  color: rgba(var(--color-base-overlay), math.div($level, 0.01));
}

@mixin centerXY() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin centerX() {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@mixin centerY() {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

@mixin textSelect() {
  &::selection {
    background-color: rgba(var(--color-base-overlay), 0.2);
    padding: 6px 0;
  }
}

@mixin ghost-basic {
  background-color: var(--color-bg-tertiary);
  animation: shimmer 1s ease-in-out infinite;
  border-radius: 6px;

  body[data-scheme='dark'] :host & {
    background-color: rgba(var(--white-rgb), 0.08);
  }

  body[window-type='launcher'][data-scheme='light'] :host & {
    background-color: var(--color-overlay-high-transparency);
  }
}

.ghost-basic {
  background-color: var(--color-bg-tertiary);
  animation: shimmer 1s ease-in-out infinite;
  border-radius: 6px;

  body[data-scheme='dark'] :host & {
    background-color: rgba(var(--white-rgb), 0.08);
  }

  body[window-type='launcher'][data-scheme='light'] :host & {
    background-color: var(--color-overlay-high-transparency);
  }
}

/** The input svg must include only one path */
@mixin draw-svg($dash: 100px, $delay: 0) {
  stroke-dasharray: $dash;
  stroke-dashoffset: $dash;
  animation-name: drawSvg;
  animation-delay: $delay;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-timing-function: ease-in-out;
}

/** This is depandent on the host-context and not gurntteed to work outside of angular component 
*   for gloal styles please refer to when-scheme-global mixin
*   Usage:
*   .selector {
*     color: red;
*     @include when-scheme($scheme) {
*       color: blue;
*     }
*/
@mixin when-scheme($scheme) {
  :host-context([data-scheme='#{$scheme}']) {
    @content;
  }
}

/** If you are setting a global style please use this mixin, unlike when-scheme this cant be nested. 
* Usage:
* @include when-scheme-global($scheme) {
  .global-class{
    // global styles
  }
}
*/
@mixin when-scheme-global($scheme) {
  [data-scheme='#{$scheme}'] {
    @content;
  }
}

$schemes: (
  'light': '/assets/loader/loader_light.gif',
  'dark': '/assets/loader/loader_dark_trans.gif',
);

@mixin main-loader($scheme) {
  width: 96px;
  height: 96px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;

  @if map-has-key($schemes, $scheme) {
    $url: inspect(map-get($schemes, $scheme));
    background-image: url($url);
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$schemes}`. Available schemes are: #{map-keys($schemes)}.";
  }
}

@mixin ext-result-item-padding {
  padding-right: 8px;
  padding-left: 8px;
  padding-top: 4px;
  padding-bottom: 4px;
}

@mixin rectangle($borderRadiusTop: 8px, $borderRadiusBottom: 8px) {
  width: 4px;
  background: var(--color-border-focused);
  border-radius: $borderRadiusTop $borderRadiusTop $borderRadiusBottom $borderRadiusBottom;
  margin-right: 8px;
}

@mixin result-hover {
  @include add-overlay(4, 4px);
}

@mixin result-selected {
  background: var(--color-overlay-medium-transparency);
}

@mixin gradient-background($background, $level) {
  background: linear-gradient(0deg, rgba(0, 0, 0, $level), rgba(0, 0, 0, $level)), var($background);
}

@mixin interactive-background($background, $color: none) {
  &:not(.p-disabled) {
    &:focus-visible {
      background: var($background);
      border: 1px solid rgba(0, 0, 0, 0.25);
      @if ($color != none) {
        color: $color;
      }
    }
    &:hover {
      @include gradient-background($background, 0.1);
      @if ($color != none) {
        color: $color;
      }
    }
    &:active {
      @include gradient-background($background, 0.15);
      @if ($color != none) {
        color: $color;
      }
    }
  }
}

@mixin interactive-secondary-background() {
  &:not(.p-disabled) {
    &:focus-visible {
      background: transparent;
      border: 1px solid var(--color-border-active);
    }
    &:hover {
      background: var(--color-overlay-low-transparency);
    }
    &:active {
      background: var(--color-overlay-medium-transparency);
    }
  }
}

@mixin interactive-success-background() {
  &:not(.p-disabled) {
    &:focus-visible {
      background: var(--color-success-secondary-high-transparency);
      border: 1px solid var(--color-success-border-focused);
    }
    &:hover {
      background: var(--color-success-secondary-medium-transparency);
    }
    &:active {
      background: var(--color-success-secondary-high-transparency);
    }
  }
}

@mixin interactive-text($color, $background: transparent) {
  &:not(.p-disabled) {
    &:focus-visible {
      filter: brightness(0.7);
      background: $background;
      border: none;
    }
    &:hover {
      filter: brightness(0.9);
      background: $background;
    }
    &:active {
      filter: brightness(0.8);
      background: $background;
    }
  }
}
