
.tree-children.tree-children-no-padding { padding-left: 0 }
.tree-children { padding-left: 20px; overflow: hidden }
.node-drop-slot { display: block; height: 2px }
.node-drop-slot.is-dragging-over { background: #ddffee; height: 20px; border: 2px dotted #888; }
.toggle-children-wrapper-expanded .toggle-children { transform: rotate(90deg) }
.toggle-children-wrapper-collapsed .toggle-children { transform: rotate(0); }
.toggle-children-wrapper {
  padding: 2px 3px 5px 1px;
}
/* tslint:disable */
.toggle-children {
  height: 8px;
  width: 9px;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 1px;
  background-repeat: no-repeat;
  background-position: center;
}
.toggle-children-placeholder {
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 1px;
  padding-right: 3px;
}
.node-content-wrapper {
  display: inline-block;
  padding: 2px 5px;
  border-radius: 2px;
  transition: background-color .15s,box-shadow .15s;
}
.node-wrapper {display: flex; align-items: flex-start;}
.node-content-wrapper-active,
.node-content-wrapper.node-content-wrapper-active:hover,
.node-content-wrapper-active.node-content-wrapper-focused {
  background: #beebff;
}
.node-content-wrapper-focused { background: #e7f4f9 }
.node-content-wrapper:hover { background: #f7fbff }
.node-content-wrapper-active, .node-content-wrapper-focused, .node-content-wrapper:hover {
  box-shadow: inset 0 0 1px #999;
}
.node-content-wrapper.is-dragging-over { background: #ddffee; box-shadow: inset 0 0 1px #999; }
.node-content-wrapper.is-dragging-over-disabled { opacity: 0.5 }

tree-viewport {
  -webkit-tap-highlight-color: transparent;
  height: 100%;
  overflow: auto;
  display: block;
}
.tree-children { padding-left: 20px }
.empty-tree-drop-slot .node-drop-slot { height: 20px; min-width: 100px }
.angular-tree-component {
  width: 100%;
  position:relative;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

tree-root .angular-tree-component-rtl {
  direction: rtl;
}
tree-root .angular-tree-component-rtl .toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(180deg) !important;
}
tree-root .angular-tree-component-rtl .tree-children {
  padding-right: 20px;
  padding-left: 0;
}

tree-node-checkbox {
  padding: 1px;
}
