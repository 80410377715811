@import '_mixin';

:host {
  min-height: 100%;
  tree-viewport::ng-deep {
    padding-bottom: 80px;
    max-width: var(--menu-width);
  }
}

tree-root {
  // REMOVE AFTER WE CHANGE THE FONT GLOBALLLY
  font-family: Inter;
  --icon-size: 14px;
}

.angular-tree-component {
  cursor: default;
}

.tree-node-expander {
  padding: 5px 0px 6px 10px;
}

.toggle-children {
  // background-image: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.541016 7.78589L4.73092 4.0716L0.541015 0.357321L0.541016 7.78589Z' fill='%23787D8E'/%3E%3C/svg%3E%0A");
  background-image: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.66687 0.316156C0.66687 0.137975 0.882298 0.0487414 1.00829 0.174734L4.52545 3.69189C4.60355 3.77 4.60355 3.89663 4.52545 3.97473L1.00829 7.49189C0.882298 7.61788 0.66687 7.52865 0.66687 7.35047V0.316156Z' fill='%23787D8E'/%3E%3C/svg%3E");
  top: 0px;
}
.tree-children {
  padding-left: calc(var(--icon-size) / 3);
  color: var(--color-text-secondary);
}

.toggle-children-wrapper {
  padding: unset;
}

.group-root {
  .tree-node-expander {
    opacity: 0;
    overflow: hidden;
  }
  .tree-node-content {
    font-weight: 400;
  }
}

.type-standard .node-wrapper {
  align-items: center;
  margin-left: 8px;
  min-height: 32px;
  margin-bottom: 2px;

  &-focused:not(&-active),
  &:active:not(&-active),
  &:hover:not(&-active) {
    @include add-overlay(4);

    &::before {
      width: calc(100% - 8px);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }

  .node-content-wrapper {
    padding-right: 8px;
  }

  &-active {
    @include add-overlay(8);
    top: 0;
    left: 0px;

    &::before {
      width: calc(100% - 8px);
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    .tree-node-content {
      color: var(--color-text-primary);
    }
    // active indicator
    &::after {
      content: '';
      position: absolute;
      width: 0px;
      height: 100%;
      top: 0;
      left: 0px;
      background-color: var(--color-primary);
      transition: all 0.2s;
      animation: slide-in-indicator 0.1s forwards;

      @keyframes slide-in-indicator {
        to {
          width: 3px;
        }
      }
    }
  }

  &:active:after {
    animation: slide-out-indicator 0.1s forwards;
    @keyframes slide-out-indicator {
      from {
        width: 2px;
      }
      to {
        width: 0px;
      }
    }
  }
}

.type-separator .node-wrapper {
  height: 1px;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: var(--gray-500);
}

.type-header .node-wrapper {
  padding-top: 8px;
  padding-bottom: 8px;
  margin-top: 16px;
  padding-left: 8px !important;
  margin-left: 16px;

  .tree-node-icon {
    display: none;
  }

  .tree-node-expander {
    display: none;
  }

  .tree-node-content {
    text-transform: uppercase;
    color: var(--color-text-tertiary);
  }
}

.tree-node {
  font-size: 12px;
  min-height: 32px;
  cursor: default;
  &.type-standard {
    cursor: pointer;
  }
}

.tree-node-level-1.tree-node {
  font-size: 14px;
}

.tree-node-content {
  display: flex;
  align-items: center;
  overflow: hidden;

  color: var(--side-bar-color-text-secondary);
  font-weight: 400;

  .tree-node-text {
    &.flex-it {
      flex: 1;
    }
    @include truncate;
  }

  // Common styles for icon (regardless type)
  .tree-node-icon {
    margin-right: 8px;

    &.icon-magnifier {
      transform: translateY(1px);
    }
  }

  // Specific styles for img icon
  .tree-node-icon-img {
    @include size(14px);
  }

  // Specific styles for font icon
  .tree-node-icon-font-icon {
    font-size: 13px;
    margin-bottom: 4px;
  }

  // Specific styles for account
  .tree-node-icon-account {
    @include size(16px);
  }

  .tree-node-badge {
    padding: 2px 8px;
    font-size: 10px;
    border-radius: 20px;
    pointer-events: none;
    color: var(--color-text-secondary);
    @include add-overlay(25, 20px, var(--color-primary-rgb));
  }
}

.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  box-shadow: unset;
}
.node-content-wrapper:hover {
  background: unset;
}

.node-content-wrapper {
  padding-right: 4px;
  overflow: hidden;
}

.node-content-wrapper:not(.with-extra-icon) {
  padding-right: 16px;
  overflow: hidden;
  flex: 1;
}

.walkthrough-target {
  &-apps,
  &-apps-last {
    @include add-overlay(8);
    background-color: var(--color-bg);
    transition: all 0.3s;
    margin-right: 8px;

    .node-wrapper-active {
      .tree-node-content {
        color: var(--color-text-secondary);
      }
      &::before,
      &::after {
        content: none;
      }
    }

    &.type-header::before {
      border-top-right-radius: 6px;
    }
  }
  &-apps-last::before {
    border-bottom-right-radius: 6px;
  }
}

[sidebar-custom-text-color='true'] .type-standard .node-wrapper-active {
  .tree-node-content {
    color: var(--side-bar-color-text-secondary);
  }
}

[sidebar-custom-color='true'] {
  &[sidebar-custom-color-theme='dark'] .type-standard .node-wrapper {
    &-focused:not(&-active),
    &:active:not(&-active),
    &:hover:not(&-active) {
      &::before {
        background-color: rgba(var(--gray-150-rgb), 0.08) !important;
      }
    }
    &-active {
      &::before {
        background-color: rgba(var(--gray-150-rgb), 0.12) !important;
      }
    }
  }
  &[sidebar-custom-color-theme='light'] .type-standard .node-wrapper {
    &-focused:not(&-active),
    &:active:not(&-active),
    &:hover:not(&-active) {
      &::before {
        background-color: rgba(var(--gray-500-rgb), 0.08) !important;
      }
    }
    &-active {
      &::before {
        background-color: rgba(var(--gray-500-rgb), 0.12) !important;
      }
    }
  }
}
