@import '_mixin';

$color: hsl(205deg 8% 39% / 35%);

.mat-option {
  height: 2.5em !important;
  margin: 8px !important;
  border-radius: 4px !important;
  font-size: 12px;
  padding: 0 8px !important;

  &:focus,
  &:focus:not(.mat-option-disabled),
  &:focus:not(.mat-active):not(.mat-option-disabled) {
    background-color: var(--bgc-focused);
  }

  &:hover:not(.mat-option-disabled),
  &:hover:not(.mat-active):not(.mat-option-disabled),
  &:hover {
    background-color: var(--bgc-hovered);
  }
}

.mat-option-text {
  color: var(--color-text-primary) !important;
  font-size: 12px;
}

.mat-primary .mat-pseudo-checkbox-checked,
.mat-primary .mat-pseudo-checkbox-indeterminate {
  background: $color;
}

.mat-pseudo-checkbox {
  color: var(--color-text-primary) !important;
  border: 1px solid !important;
  width: 14px !important;
  height: 14px !important;
}

.mat-pseudo-checkbox-checked::after {
  transform: rotate(-45deg) scale(0.75) !important;
  transform-origin: 50% 50%;
}
