:root {
  @import './common';

  body[env='native'][module-name='onboarding-native'] {
    @import 'native-onboarding';
  }

  // native windows
  body[env='native'] {
    border-radius: 6px;
    background-color: transparent;
  }
  // Non-native windows
  body:not([env='native']) {
    overflow-y: auto !important;
    overflow-x: hidden;
  }

  // for windows that doesn't recieve shadow from the OS
  &[has-native-shadow='false']:not([env='web']) {
    --window-padding-top: 8px;
    --window-padding-right: 12px;
    --window-padding-left: 12px;
    --window-padding-bottom: 16px;

    background-color: transparent !important;

    body {
      border-radius: 15px;
      padding: var(--window-padding-top) var(--window-padding-right) var(--window-padding-bottom) var(--window-padding-left);
      box-shadow: var(--window-shadow);
    }
  }
}
