@import '_link-colors';
@import '_mixin';
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: transparent !important;
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--color-text-secondary);
}

.mat-checkbox-frame {
  border-width: 1px !important;
  border-style: solid !important;
  border-color: var(--color-text-secondary);

  border-radius: 3px;
}
.mat-checkbox.cdk-focused .mat-checkbox-background,
.mat-checkbox.cdk-focused .mat-checkbox-frame {
  border-color: var(--color-text-secondary);
}

.mat-checkbox .mat-checkbox-checkmark-path {
  stroke: var(--color-text-secondary) !important;
}

//Cancel ripple effect
.mat-checkbox .mat-checkbox-ripple {
  display: none !important;
}

.mat-checkbox-checkmark-path {
  transform: scale(0.8) !important;
  transform-origin: 50% 50% !important;
  stroke-width: 2.733333px !important;
}

.mat-checkbox-mixedmark {
  height: 1px !important;
}
