gridster {
  background: inherit !important;
  &::-webkit-scrollbar {
    background: transparent;
    width: 7px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  &:hover::-webkit-scrollbar-thumb {
    background: var(--color-border-active);
    transition-delay: 2s;
    transition: 0.5s all;
  }
  &::-webkit-scrollbar-track {
    margin: 0 30px;
  }
}

gridster-item {
  background: var(--color-bg-dropdown) !important;
  box-shadow: 0px 2px 24px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  border: 1px solid var(--color-border);

  &.gridster-item-moving {
    cursor: grabbing !important;
  }
  &:hover,
  &.marked {
    border: 1px solid var(--gray-200);
    .dragging-button {
      opacity: 1;
    }
  }
  &.gridster-item-moving,
  &.gridster-item-resizing {
    background: linear-gradient(0deg, rgba(var(--gray-500-rgb), 0.04), rgba(var(--gray-500-rgb), 0.04)), var(--color-bg) !important;
    border: 1px solid var(--gray-250);
    box-shadow: var(--elevation-2), var(--elevation-2), var(--elevation-2) !important;
    transition: all 0.1s;
  }
}

gridster {
  &.mobile {
    gridster-item {
      &:hover,
      &.marked {
        .dragging-button {
          visibility: hidden;
        }
      }
    }
  }
}

gridster-preview {
  background: inherit !important;
  border: 1px dashed #a4a4a5;
  border-radius: 6px;
}

.dragging-button {
  opacity: 0;
  position: absolute;
  top: 12px;
}

@include when-scheme-global('dark') {
  gridster-item {
    &:hover {
      border: 1px solid var(--color-border-active);
    }
    &.gridster-item-moving,
    &.gridster-item-resizing {
      border: 1px solid var(--color-text-tertiary);
      background: linear-gradient(0deg, rgba(var(--gray-150-rgb), 0.04), rgba(var(--gray-150-rgb), 0.04)), var(--gray-800) !important;
    }
  }
}
