@import '_mixin';

.drag-trap {
  -webkit-app-region: drag;
}

.clickable {
  cursor: pointer;
}

.no-wrap {
  white-space: nowrap;
}

.rectangle {
  @include rectangle;
}

.rectangle-radius-top {
  @include rectangle(8px, 0);
}

.rectangle-radius-bottom {
  @include rectangle(0, 8px);
}

.rectangle-no-radius {
  @include rectangle(0, 0);
}
