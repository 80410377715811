@import '_link-colors';

.mat-form-field-underline {
  background-color: rgba(var(--color-primary-rgb), 0.42) !important;
}

.mat-form-field.mat-focused .mat-form-field-label {
  color: var(--color-primary) !important;
}

.mat-form-field-ripple {
  /*change color of underline when focused*/
  background-color: var(--color-primary) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-label {
  color: var(--color-border);
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-wrapper {
  padding-bottom: initial !important;
}

.mat-form-field.mat-focused.mat-primary .mat-select-arrow {
  color: var(--color-text-secondary);
  transform-origin: center center;
}

.mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label {
  display: none; //removes floating label
}
