/** 
/ The breakpoints are set according to the data our users screen. 
/ Initially the app will take 2/3 of the screen. 
*/

:root {
  --width-breakpoint-s: 910px;
  --width-breakpoint-l: 1280px;
  --height-breakpoint-s: 600px;
  --height-breakpoint-m: 800px;
}

$width-breakpoints: (
  'embed': ( // breakpoint for embed
    max-width: 640px,
  ),
  'extra-extra-small': (max-width: 440px,
  ),
  'extra-small': ( // breakpoint for vs code extension
    max-width: 599px,
  ),
  'small': (max-width: 910px,
    // optimized according to 1366px
  ),
  'medium': (max-width: 1280px,
  ),
  // 'regular': and (max-width: 1920px) and (min-width: 1367px)
  'large': (min-width: 1280px,
    // 1921px
  ),
  'medium-large': (min-width: 1281px,
  ),
  'small-medium': (min-width: 911px,
  ),
  'mobile': (max-width: 400px,
  ),
  ) !default;

$height-breakpoints: (
  'small': (max-height: 600px,
  ),
  'medium': (max-height: 800px,
  ),
  'medium-large': (max-height: 900px,
  ),
  ) !default;

/// Mixin to manage responsive breakpoints
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to-width($breakpoint) {

  // If the key exists in the map
  @if map-has-key($width-breakpoints, $breakpoint) {

    // Prints a media query based on the value
    @media #{inspect(map-get($width-breakpoints, $breakpoint))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($width-breakpoints)}.";
  }
}

@mixin respond-to-height($breakpoint) {
  @if map-has-key($height-breakpoints, $breakpoint) {
    @media #{inspect(map-get($height-breakpoints, $breakpoint))} {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($height-breakpoints)}.";
  }
}

/// @example
/// .selector {
///   color: red;

///  @include respond-to('small') {
///     color: blue;
///   }
/// }

/// POST-COMPILATION
/// .selector {
///   color: red;
/// }
/// @media (min-width: 767px) {
///   .selector {
///     color: blue;
///   }
/// }
