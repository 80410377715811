@import '_functions';


.headline-1,
.headline-1.reg {
  font-weight: 300;
  font-size: rem(36px);
}

.headline-1.bold {
  font-weight: 600;
}

.headline-2,
.headline-2.reg {
  font-size: rem(12px);
}

.headline-2.upper {
  text-transform: uppercase;
}

.headline-3,
.headline-3.reg {
  font-size: rem(24px);
}
